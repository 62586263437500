import React, { useMemo, useState } from 'react';
import { Feature, OrderWizardRequirement } from 'interfaces/api';
import { useGuard } from 'containers';
import styles from './styles.module.less';
import { Icon } from 'components/Icon';
import {
  faAt,
  faCalendarEdit,
  faClipboardMedical,
  faCommentAlt,
  faEdit,
  faFileCheck,
  faFileLines,
  faLink,
  faTrashCanXmark,
  faVial as faVialLight,
} from '@fortawesome/pro-light-svg-icons';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { Container } from 'components/Container';
import { freeTextByType } from 'modules/orders/containers/OrderWizard/utils';
import cx from 'classnames';
import messages from 'messages';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import { Translate, useFormatDate, useTranslate } from 'providers';
import { Control } from 'components';
import { useBasketSelectors, useUpsertBasket } from 'modules/orders/containers/OrderWizard/providers';
import { MissingRequirementOption } from 'modules/orders/interfaces';
import { useOfficeDoctorSelectors } from 'modules/orders/providers';

type MetaInfoProps = {
  requirement: OrderWizardRequirement;
  className?: any;
  readMore?: boolean;
  detail?: boolean;
  basket?: boolean;
};

const ReadMore: React.FC<{ text?: string; html?: string }> = ({ text }) => {

  const translate = useTranslate();
  const [useEllipsis, setUseEllipsis] = useState(true);

  if (!useEllipsis) {
    return <span dangerouslySetInnerHTML={{ __html: text }}/>;
  }

  return (
    <span onClick={() => setUseEllipsis(false)}>
      <HTMLEllipsis unsafeHTML={text} maxLine={2} ellipsis={'... ' + translate(messages.general.readMore)}/>
    </span>
  );
};

export const MetaInfo = (props: MetaInfoProps) => {

  const { requirement, requirement: { alias, ruleInfoText, reRequested, infoUrl }, className, readMore, detail, basket } = props;

  const translate = useTranslate();
  const formatDate = useFormatDate();

  const guard = useGuard();

  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  const freeTextAllowed = useMemo(
    () => guard({ feature: Feature.RequirementFreeText }, () => requirement.freeTextAllowed),
    [guard, requirement],
  );

  const renderedFreeText = useMemo(() => {
    if (!freeTextAllowed) {
      return undefined;
    }

    const text = freeTextByType(requirement);

    if (!text) {
      return (<i>{translate(messages.orders.wizard.freeText.noFreetextAvailable)}</i>);
    }

    return readMore ? <ReadMore text={text}/> : text;

  }, [freeTextAllowed, requirement, readMore]);

  const reRequestInfo = useMemo(() => {
    if (!reRequested) {
      return undefined;
    }

    const timeLabel = reRequested.history?.appliedAt ? translate(messages.orders.realReRequestedAt, { date: formatDate(reRequested.history.appliedAt) }) : null;
    const commentText = reRequested.comment ? translate(messages.orders.realReRequestComment, { comment: reRequested.comment }) : null;
    return <>{timeLabel}<br/>{commentText}</>;

  }, [requirement, readMore]);

  const hint = useMemo(() => guard({ feature: Feature.RequirementsHint }, () => requirement.hint), [requirement]);
  const additionalText = useMemo(() => guard({ feature: Feature.RequirementsAdditionalText }, () => requirement.hint), [requirement]);

  if (!hint && !alias && !ruleInfoText && !freeTextAllowed && !reRequested && !infoUrl) {
    return null;
  }

  const { freeTextAsDate } = requirement;
  const text = freeTextByType(requirement);

  const upsertBasket = useUpsertBasket();
  const setPending = useBasketSelectors.setPending();

  return (
    <Container className={cx('border-bottom', className)}>
      {(detail && freeTextAllowed) && (
        <div className={cx(styles.requirementInfo, styles.freeText, 'requirement-info', 'free-text')}>
          <Icon icon={faCommentAlt}/>
          {renderedFreeText}
          {basket && (
            <div className={cx(styles.controls)}>
              {text && (
                <Control
                  className={'margin-right-1'}
                  icon={faTrashCanXmark}
                  onClick={() => upsertBasket({ ...requirement, freeText: null, freeTextDate: null })}
                />
              )}

              <a
                onClick={() => {
                  setPending({ missing: freeTextAsDate ? MissingRequirementOption.RequirementFreeTextDate : MissingRequirementOption.RequirementFreeText, requirement });
                }}
              >
                <Icon icon={freeTextAsDate ? faCalendarEdit : faEdit}/>
              </a>
            </div>
          )}
        </div>
      )}
      {infoUrl && (
        <div className={cx(styles.requirementInfo, 'requirement-info')}>
          <Icon icon={faLink}/>
          <a href={infoUrl} target={'_blank'} rel="noreferrer">
            <Translate message={messages.orders.wizard.specifications}/>
          </a>
        </div>
      )}
      {alias?.length > 0 && (
        <div className={cx(styles.requirementInfo, 'requirement-info')}>
          <Icon icon={faAt}/>
          <span dangerouslySetInnerHTML={{ __html: alias }}/>
        </div>
      )}
      {hint && (
        <div className={cx(styles.requirementInfo, 'requirement-info')}>
          <Icon icon={faClipboardMedical}/>
          {readMore ? <ReadMore text={hint}/> : <span dangerouslySetInnerHTML={{ __html: hint }}/>}
        </div>
      )}
      {ruleInfoText && (
        <div className={cx(styles.requirementInfo, 'requirement-info')}>
          <Icon icon={faFileCheck}/>
          {readMore ? <ReadMore text={ruleInfoText}/> : ruleInfoText}
        </div>
      )}
      {additionalText && requirement.additionalText && (basket || detail || wizardSettings?.preferences?.orderWizardCompactView) && (
        <div className={cx(styles.requirementInfo, 'requirement-info', 'requirement-info-additional-text')}>
          <Icon icon={faFileLines}/>
          <div dangerouslySetInnerHTML={{ __html: requirement.additionalText }}/>
        </div>
      )}
      {reRequested && (
        <div className={cx(styles.requirementInfo, 'requirement-info')}>
          <Icon icon={[{ icon: faVialLight }, { icon: faPlusCircle, transform: 'shrink-7 down-4 right-3.5' }]}/>
          {reRequestInfo}
        </div>
      )}
    </Container>
  );

};
