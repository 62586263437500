import React from 'react';
import { OrderWizardRequirement, RequirementAggregationType } from 'interfaces/api';
import cx from 'classnames';
import styles from './styles.module.less';
import { Container, Icon, Tag } from 'components';
import { faCheckCircle, faShoppingCart } from '@fortawesome/pro-regular-svg-icons';
import { useRequirementFilteredForms, useUpsertBasket } from 'modules/orders/containers/OrderWizard/providers';
import { useOfficeDoctorSelectors } from 'modules/orders/providers';
import { find } from 'lodash';
import { Translate } from 'providers';
import { getRequirementPrice } from 'modules/orders/utils';
import { useGetFilterConfig } from 'modules/orders/containers/OrderWizard/utils';

export const FormAction = (props: { requirement: OrderWizardRequirement; basketRequirement: OrderWizardRequirement }) => {

  const { requirement, basketRequirement } = props;

  const getFilterConfig = useGetFilterConfig();

  const { forms } = useOfficeDoctorSelectors.wizardSettings();

  const upsertBasket = useUpsertBasket();
  const filterForms = useRequirementFilteredForms();

  const availableForms = filterForms(requirement).map(id => find(forms, { id }));

  if (availableForms.length <= 1) {
    return null;
  }

  return (
    <Container padding className={styles.forms}>

      <div>

        {availableForms.map((form) => {

          const config = getFilterConfig({ id: form.id, type: RequirementAggregationType.OrderForm, name: form.name });

          const price = getRequirementPrice(requirement, form);

          return (
            <Tag key={form.id} color={config.color} className={cx(styles.formAddToBasket, { [styles.active]: basketRequirement && basketRequirement.formId === form.id })}>
              <a
                onClick={(event) => {
                  event.stopPropagation();
                  upsertBasket({ ...requirement, formId: form.id });
                }}
              >
                <Icon {...config.icon}/>
                <Translate message={config.name}/>{price && <>&nbsp;({price})</>}
                <Icon icon={basketRequirement?.formId === form.id ? faCheckCircle : faShoppingCart}/>
              </a>
            </Tag>
          );
        })}

      </div>

    </Container>
  );

};
