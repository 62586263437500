import { useMemo } from 'react';
import { OrderRequirementsFilter, RequirementAggregationType, Feature } from 'interfaces/api';
import { createSelectors, toggleArrayItem } from 'utils/helpers';
import { filter } from 'lodash';
import { OrderAggregations } from 'modules/orders/constants';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useShallow } from 'zustand/react/shallow';
import { useGuard } from 'containers';

export interface OrderWizardParametersPropsState {
  filters: OrderRequirementsFilter[];
  groupBy: RequirementAggregationType;
  pinned: boolean;
  top: boolean;
  bak: boolean;
  query: string;
  groupFormId: number;
  pool: boolean;
}

interface OrderWizardParametersSetState {
  reset: () => void;
  setParams: (params: OrderWizardParametersPropsState) => void;
  toggleFilter: (filter: OrderRequirementsFilter, otherParams?: Partial<OrderWizardParametersPropsState>) => void;
  togglePinned: () => void;
  toggleTop: () => void;
  toggleBak: () => void;
  setGroupBy: (groupBy: RequirementAggregationType) => void;
  setGroupForm: (groupFormId: number) => void;
  setFilter: (filter: OrderRequirementsFilter) => void;
  setQuery: (value: string) => void;
  setPool: (pool: boolean) => void;
}

const initialState: OrderWizardParametersPropsState = {
  filters: [],
  groupBy: null,
  pinned: false,
  top: false,
  bak: false,
  query: null,
  groupFormId: null,
  pool: false,
};

export const isSpecialView = (groupBy: RequirementAggregationType) => [
  undefined,
  null,
  RequirementAggregationType.ProfilesOnly,
  RequirementAggregationType.GroupFormCategory,
].includes(groupBy);

export const useOrderWizardParametersStore = create<OrderWizardParametersPropsState & OrderWizardParametersSetState>()(devtools((set, get) => ({
  ...initialState,
  reset: () => set(initialState),
  setParams: params => set(params),
  toggleFilter: (filter, otherParams) => {
    const filters = toggleArrayItem(get().filters, filter, f => f.id);
    const groupBy = filter.type === get().groupBy || isSpecialView(get().groupBy) ? RequirementAggregationType.Name : get().groupBy;
    set({ filters, groupBy, ...otherParams });
  },
  togglePinned: () => set(({ groupBy, pinned }) => ({
    pinned: !pinned,
    groupBy: isSpecialView(groupBy) ? RequirementAggregationType.Name : groupBy,
    groupFormId: null,
  })),
  toggleTop: () => set(({ groupBy, top }) => ({
    top: !top,
    groupBy: isSpecialView(groupBy) ? RequirementAggregationType.Name : groupBy,
    groupFormId: null,
  })),
  toggleBak: () => set(({ groupBy, bak }) => ({
    bak: !bak,
    groupBy: isSpecialView(groupBy) ? RequirementAggregationType.Materials : groupBy,
    groupFormId: null,
  })),
  setGroupBy: groupBy => set(({ filters, pinned, top, bak }) => ({
    groupBy,
    groupFormId: null,
    filters: isSpecialView(groupBy) ? [] : filters,
    pinned: isSpecialView(groupBy) ? false : pinned,
    top: isSpecialView(groupBy) ? false : top,
    bak: isSpecialView(groupBy) ? false : bak,
  })),
  setGroupForm: groupFormId => set({
    groupFormId,
    groupBy: RequirementAggregationType.GroupFormCategory,
    filters: [],
    pinned: false,
    top: false,
    bak: false,
  }),
  setFilter: filter => set({
    filters: [filter],
    groupBy: RequirementAggregationType.Name,
    groupFormId: null,
    pinned: false,
    top: false,
    bak: false,
  }),
  setQuery: value => set(({ groupBy }) => ({
    query: value && value.length > 0 ? value : null,
    groupBy: isSpecialView(groupBy)
      ? RequirementAggregationType.Name
      : groupBy,
  })),
  setPool: pool => set(({ groupFormId, groupBy }) => ({
    pool,
    groupFormId: pool ? null : groupFormId,
    groupBy: (pool && groupBy === RequirementAggregationType.GroupFormCategory)
      ? RequirementAggregationType.Materials
      : groupBy,
  })),
})));

export const useShallowParameters = () => {
  return useOrderWizardParametersStore(
    useShallow(state => ({
      filters: state.filters,
      groupBy: state.groupBy,
      pinned: state.pinned,
      top: state.top,
      bak: state.bak,
      query: state.query,
      groupFormId: state.groupFormId,
      pool: state.pool,
    })),
  );
};

export const useOrderWizardParametersSelectors = createSelectors(useOrderWizardParametersStore).use;

export const useIsShowAll = () => {

  const filters = useOrderWizardParametersSelectors.filters();
  const groupBy = useOrderWizardParametersSelectors.groupBy();

  return groupBy === RequirementAggregationType.Name && filters.map(f => f.type).includes(RequirementAggregationType.Name);

};

export const useFilteredAggregations = () => {
  const guard = useGuard();

  const pool = useOrderWizardParametersSelectors.pool();
  const categoriesFeature = guard({ feature: Feature.RequirementCategories }, () => true);

  return useMemo(() => filter(OrderAggregations,
    ag => (!pool || ag.type !== RequirementAggregationType.OrderForm) && (categoriesFeature || ag.type !== RequirementAggregationType.Category)), [pool]);
};

