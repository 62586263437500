import React from 'react';
import { Alert, Loader } from 'components';
import { withContent, WithContentInnerProps, WithContentOuterProps } from 'hocs';
import { CSSTransition } from 'react-transition-group';
import { ErrorBoundaryHelga } from 'containers/ErrorBoundary';

export type ResponseType<T> = T extends Promise<infer U> ? U : T;

export type RequestResultComponentProps<R> = {
  data: R;
  load: (offset?: number) => void;
  next: () => Promise<any>;
};

export type ApiRequestUpdateBinding<T> = (updater: (data: T) => T) => void;

export type ApiRequest<R, T = ResponseType<R>> = {
  request: (offset?: number) => R;
  transformResponse?: (data: ResponseType<R>) => T;
  onLoaded?: (data: T) => void;
  onError?: (error: Error) => void;
  delay?: number;
  staticLoader?: boolean;
  bindReload?: (binding: () => void) => void;
  bindUpdateData?: (binding: ApiRequestUpdateBinding<T>) => void;
  interval?: number;
  errorAsAlert?: boolean;
};

export type ApiRequestProps<R, T = ResponseType<R>> = ApiRequest<R, T> & WithContentOuterProps<RequestResultComponentProps<T>>;

type Props<R, T = ResponseType<R>> = ApiRequestProps<R, T> & WithContentInnerProps<RequestResultComponentProps<T>>;

type State<R> = {
  data?: R;
  offset?: number;
  loading?: boolean;
  error?: Error;
  hasMore?: boolean;
};

class ApiRequestClass<R, T = ResponseType<R>> extends React.Component<Props<R, T>, State<T>> {

  timeout: any;

  constructor(props: Props<R, T>) {
    super(props);
    this.state = { loading: true, offset: 0 };
  }

  componentDidMount() {

    const { bindUpdateData, bindReload } = this.props;
    bindUpdateData?.(updater => this.setData(updater(this.state.data)));
    bindReload?.(this.reload);

    this.load();

  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  reload = () => {
    this.setState({ offset: 0, loading: true }, this.load);
  };

  load = () => new Promise((resolve) => {

    const { request, onLoaded, onError, transformResponse, interval } = this.props;
    let { offset } = this.state;

    window.setTimeout(async () => {

      try {
        let data: any = await request(offset > 0 ? offset : undefined);

        if (transformResponse) {
          data = transformResponse(data);
        }

        if (data?.results && data?.hasMore !== undefined) {

          if (offset > 0) {
            data.results = ((this.state.data as any).results).concat(data.results);
          }

          offset = data.indexEnd;

        }

        onLoaded?.(data);
        this.setState({ data, offset, hasMore: data?.hasMore, loading: false, error: null }, () => resolve(data));

        if (interval) {
          this.timeout = setTimeout(() => this.load(), interval);
        }

      } catch (error) {
        console.error(error);
        this.setState({ error, loading: false });
        onError?.(error);
      }

    }, this.props.delay || 0);

  });

  loadPage = (o?: number) => this.setState({ offset: o || 0, loading: true }, this.load);

  next = () => new Promise((resolve, reject) => {

    const { error, loading, hasMore, offset } = this.state;

    if (hasMore && !(loading || error)) {
      this.setState({ offset: offset + 1, loading: true }, () => this.load().then(resolve).catch(reject));
    }

  });

  setData = (data: T) => {
    this.setState({ data });
  };

  render() {

    const { renderContent, errorAsAlert } = this.props;
    const { data, error, loading, offset } = this.state;

    const next = loading ? undefined : this.next;

    const renderProps = { data, next, load: this.loadPage };

    if (error) {
      return errorAsAlert
        ? (<Alert error={error} showIcon/>)
        : (<ErrorBoundaryHelga error={error}/>);
    }

    return (
      <>
        <CSSTransition
          classNames="reveal-fade"
          timeout={200}
          appear={true}
          in={!loading || !!data}
        >
          <>
            {(!loading || !!data) && renderContent(renderProps)}
          </>
        </CSSTransition>
        {loading && <Loader delay={(this.props.delay || 0) + 200} static={offset > 0 || this.props.staticLoader}/>}
      </>
    );

  }

}

const ApiRequestComponent = withContent(ApiRequestClass);

export const ApiRequest = <R, T = ResponseType<R>>(props: ApiRequestProps<R, T>) => {
  return <ApiRequestComponent {...props}/>;
};
