import constate from 'constate';
import { useGuard } from 'containers';
import { useApi } from 'providers';
import { useOrderRulesContext } from 'modules/orders/providers/OrderRulesProvider';
import { AnamnesisQuestion, AnamnesisQuestionType, Feature, OrderAnamnesis, OrderRuleActionType, OrderRuleConditionType } from 'interfaces/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCurrentOrder, useOfficeDoctorSelectors, useOrdersSelectors, useSetOrders } from 'modules/orders/providers';
import { filter, find, flatten, uniqBy } from 'lodash';
import { getWriteableOrder } from 'modules/orders/utils';

export type PendingOrderRuleMissingAnamnesis = {
  question: AnamnesisQuestion;
  index: number;
  value?: any;
};

const useAnamnesis = () => {

  const guard = useGuard();
  const { orderWizard: { getAnamnesisQuestionTree } } = useApi();

  const { getActionsByType, getConditionsByActionType, getErrorsByName } = useOrderRulesContext();
  const { setOrderProperties } = useSetOrders();
  const currentOrder = useCurrentOrder();

  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  const [questions, setQuestions] = useState<AnamnesisQuestion[]>();

  // get anamnesis options
  const options = useMemo(
    () => guard({ feature: Feature.Anamnesis }, () => uniqBy(getActionsByType(OrderRuleActionType.Anamnesis), o => o.questionId)) || [],
    [getActionsByType],
  );

  // get question meta
  const getMetaForQuestion = useCallback(
    (question: AnamnesisQuestion) => {
      const formIds = getConditionsByActionType(OrderRuleConditionType.Form, OrderRuleActionType.Anamnesis, { questionId: question.id });
      const shortNames = getConditionsByActionType(OrderRuleConditionType.Requirement, OrderRuleActionType.Anamnesis, { questionId: question.id });
      return {
        questionId: question.id,
        ldt: question.ldt,
        questionText: question.text,
        formId: formIds[0]?.formId,
        shortName: shortNames[0]?.shortName,
      };
    },
    [getConditionsByActionType],
  );

  // reset on wizard open
  const wizardVisible = useOrdersSelectors.wizardVisible();
  useEffect(() => {
    wizardVisible && setQuestions([]);
  }, [wizardVisible]);

  useEffect(() => {
    if (options?.length > 0 && currentOrder) {
      // load question tree
      getAnamnesisQuestionTree({
        ids: options.map(q => q.questionId),
        patient: getWriteableOrder(currentOrder).patient,
      }).then(questions => setQuestions([...questions]));
    } else {
      // reset tree
      setQuestions(wizardSettings?.anamnesis);
    }
  }, [options, wizardSettings?.anamnesis]);

  // get anamnesis errors
  const anamnesisErrors: PendingOrderRuleMissingAnamnesis[] = useMemo(() => {
    const errors = flatten(getErrorsByName('OrderRulesAnamnesisError')?.map(
      result => uniqBy(result.errors, e => e.message.id).map(
        ({ message: question }) => ({ question, index: result.idx }),
      ),
    ));

    return errors.length > 0 ? errors : undefined;

  }, [getErrorsByName]);

  // check missing mandatory questions
  const missingQuestions = useMemo(
    () => currentOrder ? getUnansweredQuestions(questions || [], currentOrder.anamnesis || [], true) : [],
    [questions, currentOrder],
  );

  const getMissingPreviousAnswers = useCallback((orderAnswers: OrderAnamnesis[], cb: (orderAnswers: OrderAnamnesis[]) => void) => {
    const newDefaultAnamnesis = getUnansweredQuestions(questions || [], orderAnswers || [], false)
      .filter(q => !!q.previous)
      .map(q => ({ ...q.previous, ...getMetaForQuestion(q) }))
    ;
    if (newDefaultAnamnesis.length > 0) {
      cb([...(orderAnswers || []), ...newDefaultAnamnesis]);
    }
  }, [questions]);

  useEffect(() => {
    getMissingPreviousAnswers(currentOrder?.anamnesis, (newAnswers) => {
      setOrderProperties({ anamnesis: newAnswers });
    });
  }, [questions, currentOrder]);

  // has missing questions
  const hasMissingQuestions = useMemo(
    () => filter(missingQuestions).length > 0,
    [missingQuestions],
  );

  return { questions, anamnesisErrors, missingQuestions, hasMissingQuestions, getMetaForQuestion, getMissingPreviousAnswers };

};

const [AnamnesisProvider, useAnamnesisContext] = constate(useAnamnesis);
export { AnamnesisProvider, useAnamnesisContext };

// helper function to get missing questions from tree with given answers
const getUnansweredQuestions = (tree: AnamnesisQuestion[], orderAnswers: OrderAnamnesis[], checkMandatory: boolean): AnamnesisQuestion[] => {
  return tree.reduce((result, question) => {
    const validAnswers = orderAnswers?.filter((answer) => {
      if (answer.questionId !== question.id) {
        return false;
      }
      if (question.type === AnamnesisQuestionType.Text) {
        if (answer.fieldValue.length === 0) {
          return false;
        }
      }
      return true;
    });

    if ((!checkMandatory || question.mandatory) && !validAnswers?.length) {
      result.push(question);
    }

    const subQuestions = flatten(question.answers.filter(answer => find(validAnswers, { answerId: answer.id })).map(a => a.questions));
    return filter([...result, ...getUnansweredQuestions(subQuestions, orderAnswers, checkMandatory)]);

  }, [] as AnamnesisQuestion[]);
};
