import { OriginalReportDisplayValue, ReportAccessFields, ReportsQuickAccessConfigOrderNumberType } from 'interfaces/api';

export default {

  nav: {
    blacklist: 'Blacklist',
    custompush: 'Custompush',
    details: 'Details',
    origialreport: 'Original Report',
    customsort: 'Custom Sort',
    quickScan: 'Quick Scan',
    quickAccess: 'Quick Access',
    analyses: 'Analyses',
    laboratoryGroupFilter: 'Laboratory Group Report Filter',
  },
  reportDisplay: 'Report Display',
  cumulativeDisplay: 'Cumulative Display',
  blacklist: {
    header: 'Blacklist Settings',
    title: 'Blacklist',
    // eslint-disable-next-line max-len
    description: 'You see in this collection all for the patient basically locked investigations. You can unlock the Reports individually for each patient. You can add and delete text.',
    add: 'Add test',
    test: 'Test',
    exact: 'Exact',
    autolock: {
      title: 'Automatic Lock',
      switch: 'Lock reports automatically.',
    },
  },
  custompush: {
    header: 'Custompush Settings',
    title: 'Custompush',
    description: 'Here you can define shortcuts investigation, which were utilized for the Custom Push function.',
    add: 'Add test',
    test: 'Test',
  },
  analyses: {
    header: 'Analyses settings',
    title: 'Analyses',
    description: 'Here you can define analyses, which can enable or disable PDF download.',
    enabledMode: 'Enabled Mode',
    add: 'Add analysis',
    analysis: 'Analysis',
    pathological: 'Pathological',
    enable: 'Enable/disable download',
  },
  laboratoryGroupFilters: {
    header: 'Laboratory Group Filter Settings',
    title: 'Laboratory Group Filter',
    description: 'Here you can define laboratory group filters, which you can use in report list.',
    add: 'Add laboratory group filter',
    group: 'Laboratory Group',
    name: 'Name',
    color: 'Color',
  },
  accessStats: {
    title: 'Statistics',
    total: 'Total',
    today: 'Today',
    last7: 'Last 7 days',
    last30: 'Last 30 days',
  },
  quickScan: {
    header: 'Report Quick Scan',
    settingsTitle: 'Settings',
    enable: 'Enable',
    manualCode: 'Enable manual code entry',
    multiTargetsEnabled: 'Multiple targets',
    partialReportsEnabled: 'Enable partial reports',
    path: 'Path',
    target: 'Target',
    addTarget: 'Add Target',
    highlightPathological: 'Highlight header for pathological reports',
    analyses: {
      label: 'Analyses',
      analysis: 'Analysis',
      value: 'Value',
      highlight: 'Highlight',
      positive: 'Positive (red)',
      negative: 'Negative (green)',
      // eslint-disable-next-line max-len
      description: 'The value of first found analysis will be shown in the quick scan header. If a value is provided and matches the on in the report, the highlight color will be used.',
    },
  },
  quickAccess: {
    header: 'Report Quick Access',
    disabled: 'Report Quick Access is disabled. Enable it via .env file.',
    description: 'Here you can define the fields that are asked when a guest wants to quick access a report',
    add: 'Add Field',
    addPath: 'Add Path',
    field: 'Field',
    fieldsTitle: 'Mandatory fields',
    settingsTitle: 'Settings',
    enable: 'Enable',
    multiPathsEnabled: 'Multiple target paths',
    enableMailNotification: 'Enable E-Mail Notifications',
    partialReportsEnabled: 'Enable partial reports',
    lockedReportsEnabled: 'Enable locked reports',
    path: 'Path',
    target: 'Target',
    reportMaxAge: 'Report Max Age',
    privacyUrl: 'Privacy Url',
    privacyUrlHint: 'This will overwrite the target ENV var REACT_APP_PRIVACY_URL. If none of both is set, no privacy link will be provided.',
    orderNumberType: {
      label: 'Type',
      options: {
        [ReportsQuickAccessConfigOrderNumberType.OrderNumber]: 'Order Number',
        [ReportsQuickAccessConfigOrderNumberType.ExternalNumber]: 'External Order Number',
        [ReportsQuickAccessConfigOrderNumberType.Both]: 'Order Or External Number',
      },
    },
    orderNumber: 'Order Number',
    orderNumberLength: 'Length',
    orderNumberNumericOnly: 'Numeric values only',
    orderNumberPrefix: 'Prefix',
    orderNumberSuffix: 'Suffix',
    useOrderNumberPrefix: 'Use Prefix',
    useOrderNumberSuffix: 'Use Suffix',
    levenshtein: {
      header: 'Levenshtein Configuration',
      notAvailable: 'Levenshtein is not available right now. Please ask your admin to install postgresql-contrib and enable the fuzzystrmatch extension',
      enable: 'Enable Levenshtein Comparison',
      distance: 'Maximum Distance',
      insertCost: 'Insert Cost',
      deleteCost: 'Delete Cost',
      substituteCost: 'Substitute Cost',
    },
    pathHint: 'e.g. "befundabfrage" will enable http://localhost/befundabfrage. to enable quick access on root url for a certain domain, enter e.g. http://127.0.0.1:8081',
    fields: {
      [ReportAccessFields.Firstname]: 'First name',
      [ReportAccessFields.Lastname]: 'Last name',
      [ReportAccessFields.Birthday]: 'Birthday',
      [ReportAccessFields.OrderNumber]: 'Order Number',
      [ReportAccessFields.Zip]: 'Zip',
    },
    multiDownload: 'Multi Download',
    multiDownloadEnabled: 'Enable Multi Download',
    multiDownloadMaximumCount: {
      label: 'Maximum report count',
      description: 'If more reports are found than this number nothing will be found',
    },
    multiDownloadEnabledMandatoryTnr: 'Order number is mandatory when multi download is enabled',
  },
  customsort: {
    title: 'Custom Sort',
    entries: 'Entries',
    description: 'Here you can customize the sort of the tests on the reports.',
    columns: {
      methode: 'Methode',
      description: 'Description',
      group: 'Group',
      lg: 'Laboratory Group',
    },
    controls: {
      search: 'Search',
      pagination: {
        loadNext: 'Load next',
        loadPrevious: 'Load previous',
      },
      upload: {
        title: 'Upload CSV File',
        submit: 'Upload now',
        withHeader: 'First line is header',
      },
      download: {
        title: 'Download CSV File',
      },
    },
    bdwEnabled: 'Enable in BDW',
    cumulativeEnabled: 'Enabled in Cumulative View',
    cumulativeGrouped: 'Groups enabled in cumulative view',
  },
  details: {
    header: 'Details Settings',
    title: 'Details',
    inputLabels: {
      title: 'Title',
      firstName: 'First Name',
      name: 'Name',
      suffix: 'Suffix',
      telefon: 'Phone',
      whatsapp: 'Whatsapp contact',
      fax: 'Fax',
      notificationEmail: 'Notification Email',
      orderEmail: 'Order Email',
      webSite: 'Web Site',
      loginSite: 'Login Site',
      address: 'Address',
      city: 'City',
      postcode: 'Postcode',
      contactInfo: 'Contact Info (OA)',
      additional1: 'Additional 1',
      additional2: 'Additional 2',
      additional3: 'Additional 3',
    },
  },
  origialreport: {
    header: 'Original Report Settings',
    title: 'Original Report',
    description: 'Here you can control the priotity of the report display.',
    pdfExportSection: {
      title: 'PDF Settings',
      standard: {
        label: 'PDF Export Standard (HL7)',
        values: {
          [OriginalReportDisplayValue.ShowIfAvailable]: 'Show (if available)',
          [OriginalReportDisplayValue.NeverShow]: 'Never Show',
          [OriginalReportDisplayValue.ShowIfNothingElse]: 'Show (if nothing else)',
        },
      },
      original: {
        label: 'PDF Export Original Report (Base64)',
        values: {
          [OriginalReportDisplayValue.ShowIfAvailable]: 'Show (if available)',
          [OriginalReportDisplayValue.NeverShow]: 'Never Show',
          [OriginalReportDisplayValue.ShowIfNothingElse]: 'Show (if nothing else)',
        },
      },
    },
    bdwSection: {
      title: 'BDW Settings',
      standard: {
        label: 'BDW Standard (HL7)',
        values: {
          [OriginalReportDisplayValue.ShowIfAvailable]: 'Show (if available)',
          [OriginalReportDisplayValue.NeverShow]: 'Never Show',
          [OriginalReportDisplayValue.ShowIfNothingElse]: 'Show (if nothing else)',
        },
      },
      original: {
        label: 'BDW Original Report (Base64)',
        values: {
          [OriginalReportDisplayValue.ShowIfAvailable]: 'Show (if available)',
          [OriginalReportDisplayValue.NeverShow]: 'Never Show',
          [OriginalReportDisplayValue.ShowIfNothingElse]: 'Show (if nothing else)',
        },
      },
    },
  },
  notifications: {
    success: 'Saved settings successfully',
    error: 'An error occurred',
  },
};
