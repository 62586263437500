import React, { useEffect } from 'react';
import constate from 'constate';
import { subscribe, useApi, useApiCall } from 'providers';
import { EventType } from 'interfaces/api';
import { App } from 'antd';
import { Icon } from 'components';
import { getIcon, useNotificationLocation } from 'modules/notifications/utils';
import { useAuthUser } from 'modules/auth/providers';

const notificationCountContext = () => {

  const user = useAuthUser();
  const { notifications: { countNotifications, readNotification } } = useApi();
  const count = useApiCall(user ? countNotifications : async () => undefined);

  const { notification: antdNotification } = App.useApp();

  const notificationLocation = useNotificationLocation();

  useEffect(subscribe(EventType.NewNotification, (notification) => {

    const key = notification.id.toString() || 'key';

    count.reload();

    antdNotification.open({
      key: notification.id.toString(),
      message: notification.title,
      description: notification.body,
      placement: 'topRight',
      icon: <Icon icon={getIcon(notification)}/>,
      className: 'notification-popup',
      onClick: async () => {
        antdNotification.destroy(key);
        notificationLocation(notification);
        await readNotification({ id: notification.id });
        count.reload();
      },
    });

  }), []);

  return {
    notificationCount: count.data?.count || 0,
    reloadNotificationCount: count.reload,
    loadingNotificationCount: count.loading,
  };
};

const [NotificationCountProvider, useNotificationCount] = constate(notificationCountContext);

export { NotificationCountProvider, useNotificationCount };
