import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { default as AntInput, InputProps as AntInputProps } from 'antd/es/input';
import 'react-phone-input-2/lib/style.css';
import './Input.less';
import { Message } from 'interfaces';
import { useLocale, useTranslate } from 'providers';
import PhoneInput from 'react-phone-input-2';
import phoneInputLocaleDe from 'react-phone-input-2/lang/de.json';
import phoneInputLocaleIt from 'react-phone-input-2/lang/it.json';
import phoneInputLocaleFr from 'react-phone-input-2/lang/fr.json';
import phoneInputLocaleHu from 'react-phone-input-2/lang/hu.json';
import { LanguageCode } from 'interfaces/api';
import { useIMask } from 'react-imask';
import { FactoryOpts } from 'imask';
import { InputRef } from 'antd';
import { useEnv } from 'providers/EnvProvider';

const phoneInputLocaleFiles: Record<LanguageCode, object | undefined> = {
  [LanguageCode.DE]: phoneInputLocaleDe,
  [LanguageCode.EN]: undefined,
  [LanguageCode.IT]: phoneInputLocaleIt,
  [LanguageCode.FR]: phoneInputLocaleFr,
  [LanguageCode.HU]: phoneInputLocaleHu,
  [LanguageCode.NL]: undefined,
};

export enum InputFormat {
  Numeric,
  Alphabetic,
  Alphanumeric,
}

type OverwriteProps = {
  format?: InputFormat | string;
  placeholder?: Message;
  mask?: FactoryOpts;
};

export type InputProps = Omit<AntInputProps, keyof OverwriteProps> & OverwriteProps;

export const MaskedInput = forwardRef<InputRef, InputProps>((props, ref) => {

  const translate = useTranslate();

  const { format, placeholder, ...rest } = props;

  const [isFocused, setIsFocused] = useState(props.autoFocus);

  const inputRef = useRef(null);

  useImperativeHandle(ref, () => {
    return inputRef.current.element;
  }, []);

  const { value, setValue, unmaskedValue } = useIMask({
    ...props.mask,
    lazy: !isFocused,
    placeholderChar: '#',
  }, {
    ref: inputRef,
    onComplete: (value: string) => {
      if (props.value !== value) {
        props.onChange({ target: { value: unmaskedValue } } as React.ChangeEvent<HTMLInputElement>);
      }
    },
  });

  useEffect(() => {
    setValue(props.value as string);
  }, [props.value]);

  const onFocus: React.FocusEventHandler<HTMLInputElement> = useCallback((event) => {
    setIsFocused(true);
    props.onFocus?.(event as any);
    props.onFocus?.(event as any);
  }, [props.onFocus]);

  const onBlur: React.FocusEventHandler<HTMLInputElement> = useCallback((event) => {
    setIsFocused(false);
    props.onBlur?.(event as any);
    props.onBlur?.(event as any);
  }, [props.onBlur]);

  return (
    <AntInput
      {...rest}
      value={value}
      ref={r => inputRef.current = r?.input}
      placeholder={translate(placeholder)}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={e => setValue(e.target.value)}
    />
  );
});

export const Input = forwardRef<InputRef, InputProps>((props, ref) => {

  const { format, placeholder, ...rest } = props;

  const inputRef = useRef<InputRef>(null);

  useImperativeHandle(ref, () => {
    return inputRef.current;
  }, []);

  useEffect(() => {
    if (props.autoFocus) {
      window.setTimeout(() => inputRef.current?.focus?.(), 500);
    }
  }, []);

  const locale = useLocale();
  const translate = useTranslate();
  const preferredCountry = useEnv.PREFERRED_COUNTRY();

  if (props.inputMode === 'tel') {
    return (
      <PhoneInput
        country={preferredCountry || 'de'}
        copyNumbersOnly={false}
        preferredCountries={['de', 'at', 'ch']}
        value={props.value as string}
        onChange={value => props.onChange({ target: { value: value ? '+' + value : value } } as React.ChangeEvent<HTMLInputElement>)}
        disabled={props.disabled}
        inputClass={'ant-input'}
        regions={'europe'}
        localization={phoneInputLocaleFiles[locale]}
        countryCodeEditable={false}
      />
    );
  } else if (props.mask) {
    return <MaskedInput {...props} ref={inputRef}/>;
  }

  return (
    <AntInput
      ref={inputRef}
      placeholder={translate(placeholder)}
      {...rest}
    />
  );
});
