import React, { useCallback, useMemo, useState } from 'react';
import { Navigate, Outlet, Route, RouteProps, Routes, useLocation } from 'react-router-dom';
import { Message } from 'interfaces';
import { GuardConfig, useGuard } from 'containers';
import { IconProps } from 'components/Icon';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './Routes.less';
import { Container } from 'components/Container';
import { filter, flatMapDeep, flattenDeep, omit, sortBy } from 'lodash';
import { useRedirectStartProduct } from 'modules/auth/hooks';
import { useRenderContent, WithContentOuterProps } from 'hocs';

export type SubRoute = Omit<RouteProps, 'children'> & {
  title?: Message;
  path: string;
  icon?: IconProps;
  subRoutes?: SubRoute[];
  guard?: GuardConfig;
  navHidden?: boolean;
  navClassName?: any;
  noTopBar?: boolean;
} & WithContentOuterProps;

export const isInstanceOfSubRoute = (object: any): object is SubRoute => {
  return object?.path !== undefined;
};

export class RouteArray extends Array<SubRoute> {

  constructor(items: SubRoute[]) {
    super(...filter(items));
  }

  public flatten(route?: SubRoute): SubRoute[] {

    const routes = route ? route.subRoutes : this;

    return flatMapDeep(routes, (sub: SubRoute) => {
      return [omit(sub, 'subRoutes')].concat(this.flatten(sub)) as SubRoute[];
    });
  }

}

type RoutesProps = {
  routes: RouteArray;
};

export const useActiveRoute = (routes: RouteArray) => {

  const location = useLocation();

  const isActive = useCallback((route: SubRoute) => {
    return (location.pathname + '/').indexOf(route.path.replace('/*', '') + '/') === 0;
  }, [location]);

  const getActive = useCallback(() => {
    return filter<SubRoute>(routes?.flatten(), (route: SubRoute) => isActive(route));
  }, [isActive]);

  return { isActive, getActive };
};

const GuardedRedirectRoute: React.FC<SubRoute> = (props) => {

  const guard = useGuard();
  const [renderContent] = useRenderContent(props);
  const redirectStart = useRedirectStartProduct();

  const { guard: guardConfig } = props;

  if (guardConfig && !guard(guardConfig, () => true)) {
    redirectStart();
    return null;
  }

  return renderContent();
};

export const useGuardedRoutes = (routes: RouteArray, basePath = '/') => {

  const guard = useGuard();
  const redirectStart = useRedirectStartProduct();

  const renderRoute = useCallback((config: SubRoute): any => {

    const { path, subRoutes } = config;
    const guardedChildren = filter(subRoutes?.map(c => c.guard ? guard(c.guard, () => c) : c));

    if (!config.component && !config.children && guardedChildren?.length > 0) {
      config.children = <Navigate to={guardedChildren[0].path.replace('/*', '')}/>;
    }

    const route = (
      <Route
        key={path}
        path={path.replace(basePath, '')}
        element={<GuardedRedirectRoute {...config}/>}
      />
    );

    return [route].concat(guardedChildren.map(renderRoute));
  }, [guard, redirectStart]);

  return useMemo(() => filter(flattenDeep(routes.map(renderRoute))), [routes, renderRoute]);

};

export const GuardedRoutes: React.FC<RoutesProps & { basePath?: string }> = ({ routes, basePath }) => {

  const guardedRoute = useGuardedRoutes(routes, basePath);

  return (
    <Routes>
      {guardedRoute}
    </Routes>
  );

};

type AnimatedRoutesProps = RoutesProps & {
  animationClassName?: string;
  basePath?: string;
};

export const AnimatedRoutes: React.FC<AnimatedRoutesProps> = ({ routes, basePath, animationClassName }) => {

  const location = useLocation();
  const state = location.state as { key: string | undefined };

  const guardedRoute = useGuardedRoutes(routes, basePath);
  const getActive = useActiveRoute(routes).getActive;

  const active = useMemo(() => sortBy(getActive(), r => r.path.split('/').length * -1)[0]?.path || '/', [location.pathname]);

  const [switchKey, setSwitchKey] = useState<string | undefined>(state?.key);

  if (state?.key && switchKey !== state?.key) {
    setSwitchKey(state.key);
  }

  return (
    <>
      <div className={'animation-wrapper'}>
        <TransitionGroup appear component={null}>
          <CSSTransition
            mountOnEnter
            classNames={animationClassName || 'app-route-transition'}
            key={active}
            timeout={300}
          >
            <Container className={'animation-container'}>
              <Routes location={location} key={switchKey}>
                {guardedRoute}
              </Routes>
              <Outlet/>
            </Container>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </>
  );

};
