import cx from 'classnames';
import { ControlMenu } from 'components';
import React, { useCallback } from 'react';
import { AggregatedSuperRequirementProperties, OrderWizardRequirement } from 'interfaces/api';
import { filter, includes } from 'lodash';
import { isProfile } from 'modules/orders/containers/OrderWizard/utils';
import * as Controls from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/Controls';
import { useOrdersSelectors } from 'modules/orders/providers';
import { useBasketContext } from 'modules/orders/containers/OrderWizard/providers';

export const useRequirementHeaderControls = () => {

  const immutableRequirementIds = useOrdersSelectors.immutableRequirementIds();
  const { profileRequirementsPartialInBasket } = useBasketContext();

  return useCallback((requirement: OrderWizardRequirement | AggregatedSuperRequirementProperties, profileId?: number) => {

    return filter([
      !isProfile(requirement) && <Controls.TogglePin key={'pin'} requirement={requirement}/>,
      isProfile(requirement) && !profileRequirementsPartialInBasket(requirement) && <Controls.InstantExecution key={'execute'} requirement={requirement}/>,
      profileId && !includes(immutableRequirementIds, requirement.id) && <Controls.AddBasket key={'addBasket'} requirement={requirement} profileId={profileId}/>,
    ]);
  }, [immutableRequirementIds, profileRequirementsPartialInBasket]);

};

export const RequirementPopoverControlsMenu = (props: { requirement: OrderWizardRequirement | AggregatedSuperRequirementProperties; profileId?: number }) => {

  const { requirement, profileId } = props;
  const getControls = useRequirementHeaderControls();

  return (
    <ControlMenu
      controls={getControls(requirement, profileId)}
      className={cx('header-controls')}
    />
  );

};
