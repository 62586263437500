import { StoreOrderStatus } from 'interfaces/api';

export default {
  listHeading: 'Store order List',
  order: {
    freeText: 'Free text',
  },
  filters: {
    status: 'Order status',
  },
  status: {
    [StoreOrderStatus.Saved]: 'Saved orders',
    [StoreOrderStatus.Ordered]: 'Sent orders',
    [StoreOrderStatus.Completed]: 'Completed orders',
    [StoreOrderStatus.Cancelled]: 'Cancelled orders',
  },
  create: 'New order',
  edit: 'Edit order',
  categories: 'Categories',
  manufacturer: 'Manufacturer',
  addBasket: 'Add to basket',
  submitOrder: 'Submit order',
  saveOrder: 'Save order',
  savedOrderSuccess: 'Saved order successfully',
  submitteOrderSuccess: 'Submitted order successfully',
  submitConfirm: {
    title: 'Execute now?',
    content: 'Do you really want to execute the order now?',
  },
};
