import { useOrdersSelectors } from 'modules/orders/providers/OrdersProvider/useOrdersStore';
import { OrderPatientType } from 'interfaces/api';
import { useEffect } from 'react';
import { useApi } from 'providers';
import { useCurrentOrder } from 'modules/orders/providers/OrdersProvider/useCurrentOrder';

export const useLoadSamePatientOrders = () => {

  const orders = useOrdersSelectors.orders();
  const setSamePatientOrders = useOrdersSelectors.setSamePatientOrders();

  const patient = useCurrentOrder()?.patient;

  const { orders: { getOrdersByPatient } } = useApi();

  const loadSamePatientOrders = () => {
    if (orders.length === 1) {
      const { pid, apgid } = patient;
      if (pid > 0) {
        getOrdersByPatient({ id: pid, type: OrderPatientType.LIS }).then(setSamePatientOrders).catch(() => setSamePatientOrders([]));
      } else if (apgid > 0) {
        getOrdersByPatient({ id: apgid, type: OrderPatientType.AIS }).then(setSamePatientOrders).catch(() => setSamePatientOrders([]));
      } else {
        setSamePatientOrders([]);
      }
    } else {
      setSamePatientOrders([]);
    }
  };

  useEffect(loadSamePatientOrders, [patient?.apgid, patient?.pid]);

};
