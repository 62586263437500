import React, { HTMLAttributes, useCallback, useMemo } from 'react';
import styles from './Container.module.less';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import cx from 'classnames';
import { Breakpoint } from 'interfaces';
import { Toggle, useSharedRef } from 'hooks';
import { useMedia } from 'react-use';
import { useEnv } from 'providers/EnvProvider';

export type ContainerProps = HTMLAttributes<any> & {
  children?: React.ReactNode | React.ReactNode[];
  className?: any;
  style?: React.CSSProperties;
  horizontal?: boolean;
  grow?: boolean;
  shrink?: boolean;
  scrollX?: boolean;
  scrollY?: boolean;
  reset?: boolean;
  padding?: boolean;
  center?: boolean;
  end?: boolean;
  grid?: boolean | string;
  gridSpan?: number;
  translateZero?: boolean;
  withSideBar?: {
    toggle: Toggle;
    breakpoint: Breakpoint;
    isRight?: boolean;
  };
};

export const Container = React.forwardRef<HTMLDivElement, ContainerProps>((props, refToForward) => {

  const { children, horizontal, grow, shrink, reset, scrollX, scrollY, padding, center, end, grid, withSideBar, gridSpan, translateZero, ...rest } = props;

  const [toggled, toggle] = withSideBar?.toggle || [];

  const isWeb = useEnv.isWeb();
  const isWebScroll = useMemo(() => (scrollX || scrollY) && isWeb, [scrollX, scrollY, isWeb]);
  const isMobile = useMedia('(max-width: 768px)');

  const usePerfectScrollbar = isWebScroll && !isMobile;

  const ref = useSharedRef<any>(null, [refToForward], usePerfectScrollbar ? el => el?._container : undefined);

  const className = useMemo(() => {

    const appliedClassNames = {
      [styles.horizontal]: horizontal,
      [styles.grow]: grow,
      [styles.shrink]: shrink,
      [styles.reset]: reset,
      [styles.content]: padding,
      [styles.center]: center,
      [styles.end]: end,
      [styles.grid]: grid,
      [styles.withSideBar]: !!withSideBar,
      [styles.isRight]: !!withSideBar && withSideBar.isRight,
      [styles.toggled]: toggled,
      [styles.translateZero]: translateZero,
    };

    if (!usePerfectScrollbar) {
      appliedClassNames[styles.scrollX] = scrollX;
      appliedClassNames[styles.scrollY] = scrollY;
    }

    return cx(styles.container, props.className, appliedClassNames, withSideBar && styles[withSideBar.breakpoint]);
  }, [props, usePerfectScrollbar]);

  const perfectScrollbarOptions = useMemo(() => ({
    swipeEasing: true,
    suppressScrollX: !scrollX,
    suppressScrollY: !scrollY,
    useBothWheelAxes: !(scrollX && scrollY),
  }), [scrollX, scrollY]);

  const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (toggled) {
      e.stopPropagation();
      toggle();
    }
  }, [toggle, toggled]);

  const style = useMemo(() => {
    const styles = props.style || {};

    if (grid && grid !== true) {
      styles['gridTemplateColumns'] = `repeat(auto-fit, minmax(${grid}, 1fr))`;
    }

    if (gridSpan) {
      styles['gridColumn'] = 'span ' + gridSpan;
    }
    return styles;
  }, [props.style, grid, gridSpan]);

  const containerProps = useMemo(() => ({ ref, ...rest, className, style }), [props]);

  if (usePerfectScrollbar) {
    return (
      <PerfectScrollbar ref={ref as any} {...rest} style={props.style} className={className} options={perfectScrollbarOptions}>
        {children}
      </PerfectScrollbar>
    );
  }

  return (
    <div ref={ref} onClick={handleClick} onClickCapture={handleClick} {...containerProps}>
      {children}
    </div>
  );

});
