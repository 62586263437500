import { Doctor, OrderRequirementsFilter, OrderWizardSettings, UserType } from 'interfaces/api';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createSelectors } from 'utils/helpers';
import { MetaLogMessage, useApi, useLogger, useTranslate } from 'providers';
import { useCallback } from 'react';
import { useAuthUser } from 'modules/auth/providers';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { App } from 'antd';
import { filter, find } from 'lodash';
import messages from 'messages';

interface OfficeDoctorPropsState {
  loading: boolean;
  officeDoctor?: Doctor;
  officeDoctors?: Doctor[];
  availableFilters: OrderRequirementsFilter[];
  wizardSettings: OrderWizardSettings;
  ambiguousAid: number | true;
}

interface OfficeDoctorSetState {
  reset: () => void;
  setAvailableFilters: (filters: OrderRequirementsFilter[]) => void;
  setWizardSettings: (wizardSettings: OrderWizardSettings) => void;
}

const initialState: OfficeDoctorPropsState = {
  loading: false,
  officeDoctor: undefined,
  officeDoctors: [],
  availableFilters: [],
  wizardSettings: undefined,
  ambiguousAid: undefined,
};

export const useOfficeDoctorStore = create<OfficeDoctorPropsState & OfficeDoctorSetState>()(devtools((set, get) => ({
  ...initialState,
  reset: () => set(initialState),
  setWizardSettings: wizardSettings => set({ wizardSettings }),
  setAvailableFilters: availableFilters => set({ availableFilters }),
})));

export const useOfficeDoctorSelectors = createSelectors(useOfficeDoctorStore).use;

export const useReloadWizardSettings = () => {
  const { orderWizard: { getSettings } } = useApi();
  const setWizardSettings = useOfficeDoctorStore().setWizardSettings;
  return useCallback(async (aid: number) => {
    setWizardSettings(await getSettings({ aid }));
  }, [getSettings]);
};

export const useSetOfficeDoctor = () => {

  const { type } = useAuthUser();

  const legacy = useAuthStoreSelectors.legacy();
  const resolveLegacy = useAuthStoreSelectors.resolveLegacy();

  const { doctorGroupOffice: { readDoctorGroupOfficeAssignments } } = useApi();

  const translate = useTranslate();
  const { message } = App.useApp();

  const logger = useLogger('OfficeDoctorProvider');

  return useCallback(async (value?: Doctor) => {

    const aid = value?.aid;
    const lanr = value?.lanr;

    const nextState: Partial<OfficeDoctorPropsState> = {
      loading: true,
      ambiguousAid: aid || true,
      officeDoctors: undefined,
    };

    if (!value) {
      nextState.officeDoctor = undefined;
      nextState.wizardSettings = undefined;
    }

    useOfficeDoctorStore.setState(nextState);

    try {

      if (aid || type === UserType.ARZ) {

        const { results: assignments, hasMore } = await readDoctorGroupOfficeAssignments({ aid });
        nextState.officeDoctors = assignments;

        if (assignments.length === 1) {
          logger.debug('single assignment found', new MetaLogMessage('assignments', assignments[0]));
          nextState.officeDoctor = assignments[0];
          nextState.ambiguousAid = undefined;
        } else if (lanr) {

          const getDoctorFromAssignments = (doctors: Doctor[]) => {

            const lanrDoctor = filter(doctors, { lanr });
            const doctor = lanrDoctor.length === 1 ? lanrDoctor[0] : find(lanrDoctor, { bsnr: value.bsnr });

            if (doctor) {
              logger.debug('lanr assignment found', new MetaLogMessage('doctor', doctor));
              nextState.officeDoctor = doctor;
              nextState.ambiguousAid = undefined;
            }

          };

          // find doctor by lanr or bsnr in office doctors
          getDoctorFromAssignments(filter(assignments, { lanr }));

          if (!nextState.officeDoctor && hasMore) {
            // there are more doctors behind pagination. load by lanr
            const lanrAssignments = (await readDoctorGroupOfficeAssignments({ aid, lanr })).results;
            getDoctorFromAssignments(lanrAssignments);
          }
        }

      }

    } catch (e) {
      logger.error(e);
      message.error(translate(messages.errors.occurred));
    }

    if (legacy) {
      resolveLegacy();
    }

    useOfficeDoctorStore.setState({ ...nextState, loading: false });

  }, [readDoctorGroupOfficeAssignments, legacy, resolveLegacy]);

};
