import React, { useState } from 'react';
import { Container, Control, List, ListItem, ModalControl, SideBar } from 'components';
import styles from './Graphic.module.less';
import Chart, { ChartColors } from './Chart/Chart';
import { faChartLine, faFileMedicalAlt } from '@fortawesome/pro-light-svg-icons';
import { toggleArrayItem } from 'utils/helpers';
import messages from 'messages';
import { ApiRequest, FormLayout } from 'containers';
import { Report } from 'interfaces/api';
import { Switch } from 'antd';
import { Breakpoint } from 'interfaces';
import { Toggle, useToggle } from 'hooks';
import { filter, find } from 'lodash';
import cx from 'classnames';
import { ReportControlContext } from 'modules/reports/index';
import { FormItem } from 'components/Form/Item';
import { useCumulativeGrouping, useFavoriteAnalysesHistory } from 'modules/reports/utils';
import { useTranslate } from 'providers';

type Props = {
  data: Report;
  weightedMode?: boolean;
  toggle?: Toggle;
};

const Component = (props: Props) => {

  const [selectedValue, setSelectedValue] = useState<(string | number)[]>([props.data.values[0].name]);
  const [isMultiMode, setIsMultiMode] = useState<boolean>(false);
  const [weightedMode, setWeightedMode] = useState<boolean>(props.weightedMode ?? false);

  const reportHistoryWithFavorites = useFavoriteAnalysesHistory();
  const translate = useTranslate();

  const { data: { bid }, toggle } = props;
  const request = () => reportHistoryWithFavorites(bid);

  const { groupByValue, groupBy } = useCumulativeGrouping();

  return (
    <ApiRequest
      request={request}
      children={({ data }) => {

        const filteredData = data.filter(value => value.values.filter(({ result }) => !isNaN(parseFloat(result))).length > 1);

        const items: ListItem[] = filteredData.map((value) => {

          const current = value.values[0];

          const isSelected = selectedValue.indexOf(value.name) > -1;

          const color = selectedValue.length > 1 ? ChartColors[(selectedValue.indexOf(value.name) + ChartColors.length) % ChartColors.length] : ChartColors[0];

          return {
            id: value.name,
            title: value.name,
            subtitle: value.description,
            flag: isSelected ? color : undefined,
            className: cx({
              [styles.patho]: current.pathological,
            }),
            fields: [{
              value: `${current.result} ${current.unit}`,
              wide: true,
            }],
            groupByValue: groupByValue?.(value),
          } as ListItem;

        });

        const toggleIsMultiMode = () => {
          setIsMultiMode(!isMultiMode);
          setSelectedValue([selectedValue[0] || items[0].id]);
        };

        const list = (
          <List
            key={'list'}
            className={styles.list}
            items={items}
            selected={selectedValue.map(s => find(items, i => i.id === s))}
            groupBy={groupBy}
            onSelect={({ id }) => {
              setSelectedValue(isMultiMode ? toggleArrayItem(selectedValue, id) : [id]);
            }}
            groupHeader={(group: string) => ({
              title: group,
              className: cx({ 'list-header-favorite': group === translate(messages.reports.sidebar.settings.tabs.analyses) }),
            })}
          />
        );

        const form = (
          <FormLayout key={'form'}>

            <FormItem label={messages.reports.controls.graphic.weightByDate} className={'form-switch-container'}>
              <Switch checked={weightedMode} onChange={checked => setWeightedMode(checked)}/>
            </FormItem>

            <FormItem label={messages.reports.controls.graphic.multiMode} className={'form-switch-container'}>
              <Switch checked={isMultiMode} onChange={checked => toggleIsMultiMode()}/>
            </FormItem>

          </FormLayout>
        );

        return (
          <Container horizontal grow>

            <Container
              grow
              withSideBar={{
                toggle,
                isRight: true,
                breakpoint: Breakpoint.DesktopDown,
              }}
            >
              <Chart
                key={selectedValue.join()}
                values={filter(filter(filteredData, d => selectedValue.includes(d.name)).map(result => result?.values))}
                weightedMode={weightedMode}
                titles={filter(filter(filteredData, d => selectedValue.includes(d.name)).map(result => result?.name))}
              />
            </Container>

            <SideBar
              items={[form, list]}
              toggle={toggle}
              breakpoint={Breakpoint.DesktopDown}
              isRight
              className={styles.sidebar}
            />

          </Container>
        );

      }}
    />
  );

};

export const Graphic = React.memo((props: ReportControlContext & { weightedMode?: boolean; onClick?: () => void }) => {

  const labels = messages.reports.controls.graphic;
  const showSettingsToggle = useToggle(false);

  const translate = useTranslate();

  return (
    <ModalControl
      icon={faChartLine}
      label={translate(labels.label)}
      onClick={props.onClick}
      tooltip={{ placement: 'bottomRight' }}
      modal={{
        children: <Component {...props} toggle={showSettingsToggle}/>,
        title: props.data.patientName + ' - ' + translate(labels.label),
        fullScreen: true,
        footer: null,
        controls: [(
          <Control
            key={'analyse'}
            icon={faFileMedicalAlt}
            onClick={() => showSettingsToggle[1]()}
            className={'is-hidden-desktop-up'}
          />
        )],
      }}
    />
  );

});
