import React, { useCallback, useEffect, useMemo } from 'react';
import { AggregatedProfileProperties, AggregatedRequirementFlags, AggregatedSuperRequirementProperties, OrderWizardRequirement } from 'interfaces/api';
import { useCurrentOrder, useIncompatibleRequirements, useOfficeDoctorSelectors, useOrdersSelectors, useShowTextActions } from 'modules/orders/providers';
import { filter, find, flatten, forEach, includes, intersection, map, uniq, uniqBy, without } from 'lodash';
import { useCostUnitCheck, useLoadBasket } from './effects';
import { useSetInvoiceTo } from 'modules/orders/containers/OrderWizard/providers/BasketProvider/effects/useSetInvoiceTo';
import { ListItem } from 'components';
import { getRequirementId } from 'modules/orders/utils';
import { useBasketRequirements } from 'modules/orders/containers/OrderWizard/providers/BasketProvider/useBasketRequirements';
import { useInvoiceTo } from 'modules/orders/containers/OrderWizard/utils';
import { useOrderWizardListLayoutBindingsContext } from 'modules/orders/containers/OrderWizard/providers/useListLayoutBindingsContext';

export const useBasketContext = () => {

  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  const orders = useOrdersSelectors.orders();

  const currentOrder = useCurrentOrder();

  const basketRequirements = useMemo(() => filter(flatten(orders.map(o => o.requirements))), [orders]);
  const uniqRequirements = useMemo(() => uniqBy(basketRequirements, r => r.id), [basketRequirements]);

  // all profiles
  const profiles = useMemo(
    () => filter(wizardSettings?.profiles, p => includes(uniq(without(map(uniqRequirements, r => r.profileId), null, undefined)), p.id)),
    [wizardSettings?.profiles, uniqRequirements],
  );

  const isInBasket = useCallback(({ id }: OrderWizardRequirement) => {
    return !!find(basketRequirements, { id });
  }, [basketRequirements]);

  // get count of requirement in basket
  const getBasketCount = useCallback(({ id, selectedAnalyses, flags }: OrderWizardRequirement): number => {
    return filter(basketRequirements, (req) => {
      if (req.id === id) {
        if (flags.includes(AggregatedRequirementFlags.MultiAnalysisSelect)) {
          return intersection(selectedAnalyses, req.selectedAnalyses).length === selectedAnalyses?.length;
        }
        return true;
      }
      return false;
    }).length;
  }, [basketRequirements]);

  // requirements in pool mode not for all patients
  const poolRequirementsPartialInBasket = useCallback((requirement: OrderWizardRequirement) => {
    const count = getBasketCount(requirement);
    return count > 0 && count < orders.length;
  }, [orders.length, getBasketCount]);

  // requirements in pool mode not for all patients
  const poolRequirementsAllInBasket = useCallback((requirement: OrderWizardRequirement) => {
    const count = getBasketCount(requirement);
    return count > 0 && count === orders.length;
  }, [orders.length, basketRequirements, currentOrder, getBasketCount]);

  // function to check if profile requirements are partially in basket
  const profileRequirementsPartialInBasket = useCallback((requirement: AggregatedProfileProperties) => {
    const profile = find(wizardSettings?.profiles, { id: requirement.id });
    return (profile?.requirements || []).filter(getBasketCount).length > 0;
  }, [wizardSettings?.profiles, getBasketCount]);

  // function to check if all profile requirements are in basket
  const profileRequirementsAllInBasket = useCallback((requirement: AggregatedProfileProperties) => {
    const profile = find(wizardSettings?.profiles, { id: requirement.id });
    const all = profile?.requirements?.length;
    return all > 0 && (profile?.requirements || []).filter(getBasketCount).length === all;
  }, [wizardSettings?.profiles, getBasketCount]);

  // function to check if sub requirements of a super requirement are partially in basket
  const subRequirementsPartialInBasket = useCallback((requirement: AggregatedSuperRequirementProperties) => {
    const superRequirement = find(wizardSettings?.superRequirements, { entityId: requirement.entityId });
    return (superRequirement?.requirements || []).filter(getBasketCount).length > 0;
  }, [wizardSettings?.superRequirements, getBasketCount]);

  // function to check if all sub requirements of a super requirement are in basket
  const subRequirementsAllInBasket = useCallback((requirement: AggregatedSuperRequirementProperties) => {
    const superRequirement = find(wizardSettings?.superRequirements, { entityId: requirement.entityId });
    const all = superRequirement?.requirements?.length;
    return all > 0 && (superRequirement?.requirements || []).filter(getBasketCount).length === all;
  }, [wizardSettings?.superRequirements, getBasketCount]);

  // function to check if requirement is already in basket as analyses or sub requirement
  const inBasketAsDuplicateRequirement = useCallback(({ id, laboratoryGroup, shortName }: OrderWizardRequirement) => {
    if (wizardSettings?.preferences.orderWizardSubRequirementsInBasket) {
      return basketRequirements.filter(r => r.id !== id && r.laboratoryGroup === laboratoryGroup && r.duplicateRequirements.includes(shortName));
    }
    return [];
  }, [wizardSettings?.preferences.orderWizardSubRequirementsInBasket, basketRequirements]);

  return {
    basketRequirements,
    uniqRequirements,
    profiles,
    isInBasket,
    getBasketCount,
    profileRequirementsAllInBasket,
    profileRequirementsPartialInBasket,
    subRequirementsAllInBasket,
    subRequirementsPartialInBasket,
    poolRequirementsAllInBasket,
    poolRequirementsPartialInBasket,
    inBasketAsDuplicateRequirement,
  };

};

export const BasketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  useShowTextActions();
  useLoadBasket();
  useCostUnitCheck();

  const basketRequirements = useBasketRequirements();

  const { invoiceToActive } = useInvoiceTo();
  useSetInvoiceTo(invoiceToActive);

  const getIncompatibleRequirements = useIncompatibleRequirements();
  const getListContext = useOrderWizardListLayoutBindingsContext(s => s.getListContext);
  const updateItem = useOrderWizardListLayoutBindingsContext(s => s.updateItem);

  // update list data
  const updateData = useCallback(() => {
    forEach(getListContext()?.data, (item: ListItem<OrderWizardRequirement>) => {
      updateItem(getRequirementId(item.meta), item.meta);
    });
  }, [getListContext, updateItem]);

  useEffect(() => updateData, [history?.length, basketRequirements.map(r => r.id).join(','), getIncompatibleRequirements]);

  return children;

};
