import { UserDetailProperties } from 'interfaces/api';
import { create } from 'zustand';
import { findIndex } from 'lodash';
import { createSelectors } from 'utils/helpers';

export const AUTH_LS_LID_KEY = 'auth_lid';

const getStoredLid = () => {
  const storedLid = localStorage.getItem(AUTH_LS_LID_KEY);
  return storedLid ? parseInt(storedLid) : undefined;
};

interface AuthState {
  lid: number;
  setLid: (lid: number) => void;
  user: UserDetailProperties;
  setUser: (user: UserDetailProperties, legacy?: boolean) => void;
  logout: () => void;
  autoLogout: () => void;
  loadingAutoLogin: boolean;
  setLoadingAutoLogin: (loadingAutoLogin: boolean) => void;
  initialLoaded: boolean;
  setInitialLoaded: (initialLoaded: boolean) => void;
  avatar: File;
  setAvatar: (avatar: File) => void;
  legacy: boolean;
  setLegacy: (legacy: boolean) => void;
  resolveLegacy: () => void;
  rejectLegacy: (error: Error) => void;
  wasAutoLoggedOut: boolean;
  legacyRejected: Error;
}

const resetActions: Partial<AuthState> = {
  legacy: undefined,
  legacyRejected: undefined,
  wasAutoLoggedOut: false,
  loadingAutoLogin: false,
};

export const useAuthStore = create<AuthState>()((set, get) => ({
  legacy: undefined,
  legacyRejected: undefined,
  wasAutoLoggedOut: false,
  loadingAutoLogin: false,
  initialLoaded: false,
  lid: getStoredLid(),
  user: undefined,
  avatar: undefined,
  setLid: (lid) => {
    set({ lid });
    if (lid) {
      localStorage.setItem(AUTH_LS_LID_KEY, lid + '');
    }
  },
  setUser: (user, legacy) => {
    set({ user });
    let lid = getStoredLid() || get().lid;

    if (user && findIndex(user.labors, { lid }) === -1) {
      lid = user.labors[0].lid;
    }

    set({ user, lid, ...resetActions, legacy });
  },
  logout: () => set({ lid: undefined, user: undefined, ...resetActions }),
  autoLogout: () => set({ lid: undefined, user: undefined, ...resetActions, wasAutoLoggedOut: true }),
  setAvatar: avatar => set({ avatar }),
  setLegacy: legacy => set({ legacy }),
  resolveLegacy: () => set(resetActions),
  rejectLegacy: legacyRejected => set({ ...resetActions, legacyRejected }),
  setLoadingAutoLogin: loadingAutoLogin => set({ loadingAutoLogin }),
  setInitialLoaded: initialLoaded => set({ initialLoaded }),
}));

export const useAuthStoreSelectors = createSelectors(useAuthStore).use;
