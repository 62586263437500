import React, { useCallback, useEffect, useState } from 'react';
import constate from 'constate';
import { Platform } from 'interfaces/api';
import { getToken, removeFaceIdLoginRequested, removeToken, setFaceIdLoginRequested, setToken } from 'providers/StorageProvider';
import { useApiClient } from 'providers/ApiProvider/useApiClient';
import { FaceIDRequest } from './FaceIDRequest';
import { useEnv } from 'providers/EnvProvider';
import { BIOMETRIC_TYPE, FingerprintAIO, FingerprintOptions } from '@awesome-cordova-plugins/fingerprint-aio';

const biometricsContext = () => {

  const [available, setAvailable] = useState<BIOMETRIC_TYPE>();
  const [enabled, setEnabled] = useState(false);
  const [faceIdRequestVisible, setFaceIdRequestVisible] = useState(false);

  const platform = useEnv.platform();
  const { devices: { createDeviceToken } } = useApiClient();

  useEffect(() => {
    if (platform !== Platform.WEB) {
      FingerprintAIO.isAvailable().then(setAvailable).catch((reason) => {
        setAvailable(undefined);
      });
    }
  });

  useEffect(() => {
    if (available !== undefined) {
      getToken().then(credentials => setEnabled(!!credentials)).catch(console.error);
    }
  }, [available]);

  const show = useCallback((options: FingerprintOptions) => new Promise((resolve, reject) => {
    if (available !== undefined) {
      FingerprintAIO.show(options).then(resolve).catch(reject);
    }
  }), [available]);

  const showFaceIdRequest = useCallback(async () => {
    if (available !== undefined && !await getToken()) {
      setFaceIdRequestVisible(true);
      await setFaceIdLoginRequested();
    }
  }, [available]);

  const hideFaceIdRequest = useCallback(async () => {
    setFaceIdRequestVisible(false);
    await setFaceIdLoginRequested();
  }, []);

  const enable = useCallback(async () => {
    try {
      await show({});
      const token = await createDeviceToken();
      await setToken(token.token);
      setEnabled(true);
    } catch (e) {
      setEnabled(false);
      console.error(e);
    }
  }, [createDeviceToken, show]);

  const disable = useCallback(async () => {
    await removeToken();
    await removeFaceIdLoginRequested();
    setEnabled(false);
  }, []);

  return {
    available,
    enabled,
    show,
    enable,
    disable,
    showFaceIdRequest,
    hideFaceIdRequest,
    faceIdRequestVisible,
  };

};

const [BiometricsInternalProvider, useBiometrics] = constate(biometricsContext);
export { useBiometrics };

export const BiometricsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  return (
    <BiometricsInternalProvider>
      <FaceIDRequest>
        {children}
      </FaceIDRequest>
    </BiometricsInternalProvider>
  );
};
